import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useQuery } from "@apollo/client";
import {
  Hero,
  HeroContainer,
  TabsContainer,
  Container,
  AccordionContainer,
} from "./DashboardView.style";

// import useTabs from "../../hooks/useTabs";

import { Layout } from "../../components/Layout/Layout";
import { Loading } from "../../components/Loading/Loading";
import { Header } from "../../components/Header/Header";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { Tabs, Tab } from "../../ui/Tabs/Tabs";
import { Footer } from "../../components/Footer/Footer";
import { LoyaltyCard } from "../../components/LoyaltyCard/LoyaltyCard";
import { Accordion } from "./Accordion/Accordion";

import { PointsEarned } from "../../components/Dashboard/PointsEarned";
import { PointsRedeemed } from "../../components/Dashboard/PointsRedeemed";
import { EarnBonusPoints } from "../../components/MyAccount/EarnBonusPoints";
import { MySalon } from "../../components/MyAccount/MySalon";
import { MyProfile } from "../../components/MyAccount/MyProfile";

import { GET_DASHBOARD_DATA } from "../../components/Dashboard/config";

export const DashboardView = () => {
  const [initialExpanded, setInitialExpanded] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();
  const {
    loading: dashboardLoading,
    error: dashboardError,
    data: dashboardData,
  } = useQuery(GET_DASHBOARD_DATA);

  console.info({ dashboardData });

  // const { activeTab, selectTab } = useTabs();

  return dashboardLoading ? (
    <Loading global />
  ) : (
    <Layout>
      <Header />
      <Breadcrumbs
        breadcrumbs={[
          {
            to: "/",
            label: intl.formatMessage({
              defaultMessage: "Home",
            }),
          },
          {
            to: null,
            label: intl.formatMessage({
              defaultMessage: "Dashboard",
            }),
          },
        ]}
      />

      <Hero>
        <HeroContainer>
          <LoyaltyCard
            tier={
              dashboardData?.loyaltyresult?.find?.docs?.[0]?.qualifyingTier ??
              "Starter"
            }
            pts={dashboardData?.me?.pointsBalance ?? 0}
            memberSince={dashboardData?.me?.user?.dc}
            group={dashboardData?.me?.user?.salon?.n}
            userName={`${dashboardData?.me?.user?.firstname} ${dashboardData?.me?.user?.lastname}`}
          />
        </HeroContainer>
      </Hero>

      <TabsContainer>
        <Tabs>
          <Tab
            className={location.pathname.endsWith("/dashboard") && "--active"}
            type="button"
            onClick={() =>
              navigate(`/${intl.locale}/dashboard`, {
                preventScrollReset: true,
              })
            }
          >
            <FormattedMessage defaultMessage="Points Activity" />
          </Tab>
          <Tab
            className={
              location.pathname.endsWith("/dashboard/orders") && "--active"
            }
            type="button"
            onClick={() =>
              navigate(`/${intl.locale}/dashboard/orders`, {
                preventScrollReset: true,
              })
            }
          >
            <FormattedMessage defaultMessage="Orders Placed" />
          </Tab>
          <Tab
            className={
              location.pathname.endsWith("/dashboard/bonus-points") &&
              "--active"
            }
            type="button"
            onClick={() =>
              navigate(`/${intl.locale}/dashboard/bonus-points`, {
                preventScrollReset: true,
              })
            }
          >
            <FormattedMessage defaultMessage="Bonus Points" />
          </Tab>
          <Tab
            className={
              location.pathname.endsWith("/dashboard/salon-profile") &&
              "--active"
            }
            type="button"
            onClick={() =>
              navigate(`/${intl.locale}/dashboard/salon-profile`, {
                preventScrollReset: true,
              })
            }
          >
            <FormattedMessage defaultMessage="Salon Profile" />
          </Tab>
          <Tab
            className={
              location.pathname.endsWith("/dashboard/my-account") && "--active"
            }
            type="button"
            onClick={() =>
              navigate(`/${intl.locale}/dashboard/my-account`, {
                preventScrollReset: true,
              })
            }
          >
            <FormattedMessage defaultMessage="My Account" />
          </Tab>
        </Tabs>

        <AnimatePresence mode="sync">
          <Container
            key={location.pathname}
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.4 }}
          >
            <Outlet context={dashboardData} />
          </Container>
        </AnimatePresence>
      </TabsContainer>

      <AccordionContainer>
        <Accordion
          expanded={initialExpanded}
          setExpanded={(e) => {
            console.info({ e });
            setInitialExpanded(e);
          }}
          items={[
            {
              title: intl.formatMessage({
                defaultMessage: "Points Activity",
              }),
              content: <PointsEarned />,
            },
            {
              title: intl.formatMessage({
                defaultMessage: "Orders Placed",
              }),
              content: <PointsRedeemed />,
            },
            {
              title: intl.formatMessage({
                defaultMessage: "Bonus Points",
              }),
              content: (
                <EarnBonusPoints
                  data={dashboardData}
                  onSetExpanded={(e) => setInitialExpanded(e)}
                />
              ),
            },
            {
              title: intl.formatMessage({
                defaultMessage: "Salon Profile",
              }),
              content: <MySalon data={dashboardData} />,
            },
            {
              title: intl.formatMessage({
                defaultMessage: "My Account",
              }),
              content: <MyProfile data={dashboardData} />,
            },
          ]}
        />
      </AccordionContainer>

      <Footer />
    </Layout>
  );
};
