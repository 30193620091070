import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

import { Root, Header, IconContainer, Body } from "./Accordion.style";

export function Accordion({ items = [], expanded = 0, setExpanded }) {
  // const [ss, setExpanded] = useState(expanded);

  return items.map(({ title, content }, idx) => (
    <AccordionItem
      key={idx + title}
      idx={idx}
      title={title}
      content={content}
      expanded={expanded}
      setExpanded={setExpanded}
    />
  ));
}

function AccordionItem({ idx, title, content, expanded, setExpanded }) {
  const isOpen = idx === expanded;

  return (
    <Root>
      <Header
        $isOpen={isOpen}
        onClick={() => setExpanded(isOpen ? false : idx)}
      >
        <span>{title}</span>
        <IconContainer>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={24}
            height={24}
          >
            <path
              fill="currentColor"
              d={
                isOpen
                  ? "m8.932 4.247 6.42 6.435-1.171 1.17-5.936-5.945h-.493L1.82 11.852l-1.168-1.17 6.417-6.435h1.863Z"
                  : "M6.797 11.734.377 5.299l1.171-1.17 5.936 5.945h.493l5.932-5.945 1.168 1.17-6.417 6.435H6.797Z"
              }
            />
          </svg>
        </IconContainer>
      </Header>

      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <Body>{content}</Body>
          </motion.div>
        )}
      </AnimatePresence>
    </Root>
  );
}
