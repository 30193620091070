import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 1023px) {
    padding-bottom: 80px;
  }

  @media (min-width: 1024px) {
    padding-bottom: 100px;
  }
`;

export const LoadingContainer = styled.div`
  position: relative;
  width: 100%;

  @media (max-width: 1023px) {
    height: 480px;
  }

  @media (min-width: 1024px) {
    height: 640px;
  }
`;

export const Title = styled.div`
  margin-top: 16px;
  text-align: center;

  @media (max-width: 1023px) {
    max-width: 327px;
  }

  @media (min-width: 1024px) {
    max-width: 650px;
  }
`;

export const DetailsList = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;

  @media (max-width: 1023px) {
    margin-top: 24px;
  }

  @media (min-width: 1024px) {
    margin-top: 32px;
  }
`;

export const DetailsItem = styled.li`
  display: flex;
  align-items: center;
  color: var(--color-text-2);

  &:not(:last-child)::after {
    //content: "•";
    position: relative;
    padding: 0 8px;
    font-size: 13px;
  }
`;

export const DropdownButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 32px;
  height: 32px;
  padding: 0;
  font-size: 21px;
  border-radius: 50%;

  &:hover,
  &.--isActive {
    background-color: var(--color-bg-3);
  }

  svg {
    pointer-events: none;
  }
`;

export const BannerContainer = styled.div`
  position: relative;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 248px;
    border: none;
  }

  @media (max-width: 1023px) {
    height: 360px;
    margin-top: 24px;
    margin-bottom: 48px;

    iframe {
      height: 360px;
    }
  }

  @media (min-width: 1024px) {
    height: 550px;
    margin-top: 32px;
    margin-bottom: 72px;

    iframe {
      height: 550px;
    }
  }
`;

export const PlayButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 0;
  color: #ea3323;
  font-size: 90px;
  transform: translate(-50%, -50%);
`;

export const ContentContainer = styled.div`
  width: 100%;
  max-width: 816px;

  @media (max-width: 1023px) {
    padding: 0 24px;
  }

  h2 {
    margin-top: 56px;
    margin-bottom: 24px;
  }

  h3 {
    margin-top: 56px;

    @media (max-width: 1023px) {
      margin-bottom: 24px;
    }

    @media (min-width: 1024px) {
      margin-bottom: 16px;
    }
  }

  p + p {
    margin-top: 16px;
  }

  img {
    margin: 56px 0;
  }
`;
