import React, { useState, useRef, useContext, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Slider from "react-slick";
import { useQuery, gql } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import {
  Root,
  TopBar,
  TopBarBody,
  TopBarSlide,
  Container,
  HeaderContainer,
  MobileMenuButton,
  LogoLink,
  Logo,
  NavContainer,
  NavList,
  NavItem,
  SearchButton,
  ActionsContainer,
  UserContainer,
  Actions,
  Action,
  ActionBadge,
} from "./Header.style";

import clubhLogoSrc from "../../assets/clubh-logo.svg";

import { UserContext } from "../../context/UserContext";
import { getUserAvatar } from "../../utils/user";

import { Link, NavLink } from "../Link/Link";
import { MobileMenu } from "../MobileMenu/MobileMenu";
import { HeaderUserBadge } from "../HeaderUserBadge/HeaderUserBadge";
import { Button } from "../../ui/Button/Button";
import { Icon } from "../../ui/Icon/Icon";
import { Typography } from "../../ui/Typography/Typography";
import { ModalRoot } from "../Modals/ModalRoot/ModalRoot";
import { ModalSearch } from "../Modals/ModalSearch/ModalSearch";
import { MenuDropdown } from "../../ui/MenuDropdown/MenuDropdown";

const sliderSettings = {
  arrows: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 6000,
};

const GET_HEADER_CART_DATA = gql`
  query GetHeaderCartData($locale: String) {
    me {
      user {
        _id
        avatar {
          media {
            _id
          }
        }
      }
      pointsBalance
      cart(locale: $locale) {
        _id
        items {
          _id
          product {
            ... on Reward {
              _id
            }
          }
        }
      }
      wishlist(locale: $locale) {
        _id
        items {
          _id
          product {
            ... on Reward {
              _id
            }
          }
        }
      }
    }
  }
`;

export const Header = () => {
  const intl = useIntl();
  const locale = intl.locale;
  const navigate = useNavigate();
  const rootRef = useRef();
  const menuRef = useRef();
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(false);
  const [modalSearchVisible, setModalSearchVisible] = useState(false);
  const { signOut } = useContext(UserContext);

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleSticky);

    return () => {
      window.removeEventListener("scroll", handleSticky);
    };
  }, []);

  const handleSticky = () => {
    setIsSticky(window.scrollY >= 120);
  };

  const navLinks = [
    {
      url: "/rewards",
      label: intl.formatMessage({ defaultMessage: "Rewards" }),
    },
    {
      url: "/offers",
      label: intl.formatMessage({ defaultMessage: "Offers" }),
      end: true,
    },
    // {
    //   url: "/education",
    //   label: intl.formatMessage({ defaultMessage: "Education" }),
    // },
    {
      url: "/experience",
      label: intl.formatMessage({ defaultMessage: "Experiences" }),
    },
    {
      url: "/community",
      label: intl.formatMessage({ defaultMessage: "Community" }),
    },
    {
      url: "/membership-benefits",
      label: intl.formatMessage({ defaultMessage: "Membership" }),
    },
  ];

  const {
    loading: cartLoading,
    error: cartError,
    data: cartData,
  } = useQuery(GET_HEADER_CART_DATA, {
    variables: {
      locale,
    },
  });

  // console.info({ cartData });

  const handleOpenMenu = () => {
    if (!mobileMenuIsOpen) {
      setHeaderHeight(rootRef.current.clientHeight);
    }
    setMobileMenuIsOpen(!mobileMenuIsOpen);
  };

  return (
    <Root ref={rootRef}>
      <TopBar>
        <TopBarBody>
          <Slider {...sliderSettings}>
            <TopBarSlide as={Link} to="/dashboard/bonus-points">
              <FormattedMessage defaultMessage="Refer a friend or answer our survey to earn up to 1,000 points today!" />
            </TopBarSlide>
            {/* <TopBarSlide as={Link} to="/sweepintostyle">
              <FormattedMessage defaultMessage="Sign up for Club H by June 30th and you’re automatically entered to win!" />
            </TopBarSlide> */}
          </Slider>
        </TopBarBody>
      </TopBar>

      <Container className={isSticky && "--isSticky"}>
        <HeaderContainer className={isSticky && "--isSticky"}>
          <MobileMenuButton onClick={handleOpenMenu}>
            {mobileMenuIsOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 33 33"
                width={33}
              >
                <path d="M6.5 27.401a.9.9 0 0 1-.636-1.536l9.4-9.4L5.866 7.14a.902.902 0 0 1 1.268-1.28l9.403 9.332 9.327-9.327a.9.9 0 1 1 1.273 1.273l-9.322 9.322 9.319 9.247a.9.9 0 1 1-1.268 1.278l-9.324-9.252-9.405 9.405a.904.904 0 0 1-.637.263Z" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 21 21"
                width={21}
              >
                <path d="M20.5 18H.5a.5.5 0 0 1 0-1h20a.5.5 0 0 1 0 1Zm0-7H.5a.5.5 0 0 1 0-1h20a.5.5 0 0 1 0 1Zm0-7H.5a.5.5 0 0 1 0-1h20a.5.5 0 0 1 0 1Z" />
              </svg>
            )}
          </MobileMenuButton>

          {mobileMenuIsOpen && (
            <MobileMenu
              topOffset={headerHeight}
              imageSrc={getUserAvatar(cartData?.me?.user)}
              pts={cartData?.me?.pointsBalance ?? 0}
              firstname={cartData?.me?.user?.firstname}
            />
          )}

          <LogoLink as={Link} to="/">
            <Logo src={clubhLogoSrc} alt="Club H" />
          </LogoLink>

          <NavContainer>
            <NavList>
              {navLinks.map(({ url, label, end }) => (
                <NavItem key={url + label}>
                  <NavLink
                    to={url}
                    className={({ isActive }) => (isActive ? "--isActive" : "")}
                    end
                  >
                    <Typography type="h4">{label}</Typography>
                  </NavLink>
                </NavItem>
              ))}
            </NavList>

            <SearchButton
              type="button"
              onClick={() => setModalSearchVisible(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 12 12"
                width={18}
                height={18}
              >
                <path
                  fill="currentcolor"
                  d="m11.308 12.009.706-.706-1.992-1.994v-.66C12.445 5.17 10.194.372 5.969.012 1.744-.347-1.285 4.002.516 7.84c1.448 3.086 5.323 4.129 8.125 2.187h.686l1.981 1.982Zm-10.294-6.5c0-3.464 3.75-5.629 6.75-3.897 3 1.732 3 6.062 0 7.794a4.5 4.5 0 0 1-6.75-3.897Z"
                />
              </svg>
            </SearchButton>
          </NavContainer>

          <ActionsContainer>
            <UserContainer>
              {cartData?.me?.user?._id ? (
                <HeaderUserBadge
                  url={"/dashboard"}
                  imageSrc={getUserAvatar(cartData?.me?.user)}
                  pts={cartData?.me?.pointsBalance ?? 0}
                />
              ) : (
                <Button as={Link} to="/auth/register">
                  <FormattedMessage defaultMessage="join now" />
                </Button>
              )}
            </UserContainer>
            {!cartData?.me?.user?._id && (
              <UserContainer>
                <Button
                  as={Link}
                  to="/auth"
                  variant="secondary"
                  arrow={false}
                  style={{
                    color: "var(--color-border-2)",
                    fontWeight: 500,
                    textTransform: "capitalize",
                  }}
                >
                  <FormattedMessage defaultMessage="log in" />
                </Button>
              </UserContainer>
            )}

            <Actions>
              <Action
                className="--hideOnDesktop"
                type="button"
                onClick={() => setModalSearchVisible(true)}
              >
                <Icon icon="search" />
              </Action>
              <Action as={Link} to="/favourites">
                <Icon icon="heart" />
                {cartData?.me?.wishlist?.items?.length > 0 && (
                  <ActionBadge>
                    {cartData?.me?.wishlist?.items?.length}
                  </ActionBadge>
                )}
              </Action>
              <Action as={Link} to="/cart">
                <Icon icon="basket" />
                {cartData?.me?.cart?.items?.length > 0 && (
                  <ActionBadge>{cartData?.me?.cart?.items?.length}</ActionBadge>
                )}
              </Action>

              <Action
                className={`headerMenuDropdownAction --hideOnMobile ${
                  menuIsOpen && "--isActive"
                }`}
                ref={menuRef}
                onClick={() => setMenuIsOpen(!menuIsOpen)}
              >
                <Icon icon="ellipsis-vertical" />
                <MenuDropdown
                  isOpen={menuIsOpen}
                  onClose={() => setMenuIsOpen(false)}
                  ignoreClassName="headerMenuDropdownAction"
                >
                  {/* <MenuDropdown.Item as={Link} to="/membership-benefits">
                    <Icon icon="cup" />
                    <Typography type="h4">
                      <FormattedMessage defaultMessage="Membership Benefits" />
                    </Typography>
                  </MenuDropdown.Item> */}
                  <MenuDropdown.Item as={Link} to="/dashboard">
                    <Icon icon="box" />
                    <Typography type="h4">
                      <FormattedMessage defaultMessage="My Dashboard" />
                    </Typography>
                  </MenuDropdown.Item>
                  <MenuDropdown.Item as={Link} to="/dashboard/my-account">
                    <Icon icon="user" />
                    <Typography type="h4">
                      <FormattedMessage defaultMessage="My Account" />
                    </Typography>
                  </MenuDropdown.Item>
                  <MenuDropdown.Item
                    as={Link}
                    to="https://clubhrewards.zendesk.com"
                    target="_blank"
                  >
                    <Icon icon="message" />
                    <Typography type="h4">
                      <FormattedMessage defaultMessage="FAQ" />
                    </Typography>
                  </MenuDropdown.Item>
                  <MenuDropdown.Item
                    as={Link}
                    locale={locale === "fr-CA" ? "en-CA" : "fr-CA"}
                  >
                    <Icon icon="globe" />
                    <Typography type="h4">
                      {locale === "fr-CA" ? (
                        <FormattedMessage defaultMessage="English" />
                      ) : (
                        <FormattedMessage defaultMessage="French" />
                      )}
                    </Typography>
                  </MenuDropdown.Item>
                  {cartData?.me?.user?._id != null ? (
                    <MenuDropdown.Item
                      as={Link}
                      //to="/"
                      onClick={async (e) => {
                        e.preventDefault();
                        await signOut();
                        navigate("/");
                      }}
                    >
                      <Icon icon="arrow-right-from-bracket" />
                      <Typography type="h4">
                        <FormattedMessage defaultMessage="Log Out" />
                      </Typography>
                    </MenuDropdown.Item>
                  ) : (
                    <MenuDropdown.Item as={Link} to="/auth">
                      <Icon icon="arrow-right-long" />
                      <Typography type="h4">
                        <FormattedMessage defaultMessage="Log In" />
                      </Typography>
                    </MenuDropdown.Item>
                  )}
                </MenuDropdown>
              </Action>
            </Actions>
          </ActionsContainer>
        </HeaderContainer>
      </Container>

      <ModalRoot
        fullScreen
        isVisible={modalSearchVisible}
        onClose={() => setModalSearchVisible(false)}
      >
        <ModalSearch onClose={() => setModalSearchVisible(false)} />
      </ModalRoot>
    </Root>
  );
};
