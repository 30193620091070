import React, { useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import { FormattedMessage, useIntl } from "react-intl";
import {
  FavouritesContainer,
  PaginationContainer,
} from "./FavouritesView.style";

import { Layout } from "../../components/Layout/Layout";
import { Header } from "../../components/Header/Header";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { PageTitle } from "../../components/PageTitle/PageTitle";
import { Footer } from "../../components/Footer/Footer";
import { PageSubheader } from "../../components/PageSubheader/PageSubheader";
import { Typography } from "../../ui/Typography/Typography";
import { FavouritesGrid } from "../../components/FavouritesGrid/FavouritesGrid";
import { FavouritesItem } from "../../components/FavouritesItem/FavouritesItem";
import { Pagination } from "../../components/Pagination/Pagination";
import { PromoBrand } from "../../components/Sections/PromoBrand/PromoBrand";
import { TrendingRewards } from "../../components/Sections/TrendingRewards/TrendingRewards";
import { ModalRoot } from "../../components/Modals/ModalRoot/ModalRoot";
import { ModalAddedToCart } from "../../components/Modals/ModalAddedToCart/ModalAddedToCart";
import {
  toast,
  ToastNotification,
} from "../../ui/ToastNotification/ToastNotification";

import {
  ADD_TO_CART,
  REMOVE_FROM_WISHLIST,
  // UPDATE_CART,
  GET_WISHLIST_DETAILS,
} from "../RewardsView/rewardsViewConfig";
import { apiDomain, apiSiteId } from "../../config";
import { brands } from "../../config/brands";

export const FavouritesView = () => {
  const intl = useIntl();
  const [modalAddToCartData, setModalAddToCartData] = useState(null);

  const {
    loading: wishlistLoading,
    error: wishlistError,
    data: wishlistData,
  } = useQuery(GET_WISHLIST_DETAILS, {
    variables: {
      locale: intl.locale,
    },
  });

  const [addToCart] = useMutation(ADD_TO_CART);

  const [removeFromWishlist] = useMutation(REMOVE_FROM_WISHLIST, {
    refetchQueries: ["GetHeaderCartData"],
  });

  // const data = [];
  const data = wishlistData?.me?.wishlist?.items || [];

  const results = wishlistLoading
    ? "loading..."
    : `${data?.length} ${
        data.length === 1
          ? intl.formatMessage({ defaultMessage: "product" })
          : intl.formatMessage({ defaultMessage: "products" })
      }`;

  const handleRemoveFromWishlist = ({ item }) => {
    toast.warn(
      <ToastNotification>
        <FormattedMessage
          defaultMessage="{productName} removed from wishlist."
          values={{ productName: item?.n }}
        />
      </ToastNotification>
    );
    return removeFromWishlist({
      variables: { productId: item._id, locale: intl.locale },
    });
  };

  const handleMoveToCart = ({ item, brandName, imgSrc }) => {
    setModalAddToCartData({
      imgSrc,
      brandName,
      productName: item?.n,
      points: item?.ecommerce?.price?.amount,
      oldPts: item?.ecommerce?.fullPrice?.amount,
    });
    const addCartPromise = addToCart({
      variables: { productId: item._id, qty: 1, locale: intl.locale },
    });
    // const removeFromWishlistPromise = removeFromWishlist({
    //   variables: { productId: item._id, locale: intl.locale },
    // });
    return Promise.all([
      addCartPromise,
      // removeFromWishlistPromise
    ]);
  };

  return wishlistLoading ? null : (
    <Layout>
      <Header />
      <Breadcrumbs
        breadcrumbs={[
          { to: "/", label: intl.formatMessage({ defaultMessage: "Home" }) },
          {
            to: null,
            label: intl.formatMessage({ defaultMessage: "Favourites" }),
          },
        ]}
      />
      <PageTitle title={intl.formatMessage({ defaultMessage: "Favourites" })} />
      {data?.length > 0 && (
        <PageSubheader>
          <Typography type="p4" color="#8f95a5">
            {results}
          </Typography>
        </PageSubheader>
      )}

      <FavouritesContainer>
        <FavouritesGrid totalCount={data.length}>
          {data.map((item, i) => {
            const product = item?.product;
            const brandConfig = brands.find((b) => b.id === product.brand?._id);
            const imgSrc =
              product?.gallery?.[0]?.media?._id != null
                ? `https://${apiDomain}/${apiSiteId}/asset/${product.gallery[0].media._id}?type=fill&width=604&height=752`
                : `https://picsum.photos/id/${i + 50}/1000/1000`;
            return (
              <FavouritesItem
                key={item._id}
                imageSrc={imgSrc}
                brandName={brandConfig?.label}
                brandUrl={`/brands/${brandConfig.slug}`}
                productName={product?.n}
                productUrl={`/rewards/${product?.sl}`}
                pts={product?.ecommerce?.price?.amount}
                oldPts={product?.ecommerce?.fullPrice?.amount}
                outOfStock={product?.e === false}
                onRemove={() => handleRemoveFromWishlist({ item: product })}
                onAddCart={() =>
                  handleMoveToCart({
                    item: product,
                    imgSrc,
                    brandName: brandConfig?.label,
                  })
                }
              />
            );
          })}
        </FavouritesGrid>

        {/* {data.length > 0 && (
          <PaginationContainer>
            <Pagination
              totalCount={data.length}
              currentPage={1}
              pageSize={15}
              onPaginate={(e) => console.info("onPaginate: ", e)}
              append={
                <Typography type="p4" color="#8f95a5">
                  {results}
                </Typography>
              }
            />
          </PaginationContainer>
        )} */}
      </FavouritesContainer>

      <PromoBrand />

      <TrendingRewards
        title={intl.formatMessage({ defaultMessage: "Trending Rewards" })}
      />

      <Footer />

      <ModalRoot
        isVisible={modalAddToCartData != null}
        onClose={() => setModalAddToCartData(null)}
      >
        <ModalAddedToCart
          onClose={() => setModalAddToCartData(null)}
          qty={1}
          {...modalAddToCartData}
        />
      </ModalRoot>
    </Layout>
  );
};
