import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;

  @media (min-width: 1024px) {
    padding: 0 48px;
  }
`;

export const Header = styled.div`
  align-items: center;
  position: relative;
  width: 100%;

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px 0;
    border-bottom: 1px solid #dcdfe9;
  }

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 48px 0;
  }
`;

export const Logo = styled.div`
  @media (max-width: 1023px) {
    width: 104px;
    margin-bottom: 24px;
  }

  @media (min-width: 1024px) {
    width: 140px;
  }
`;

export const Close = styled.button`
  margin-left: auto;
  @media (max-width: 1023px) {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 18px;
    right: 18px;
    width: 32px;
    height: 32px;
    font-size: 20px;
  }

  @media (min-width: 1024px) {
    font-size: 26px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1144px;
  overflow-y: auto;

  @media (max-width: 1023px) {
    padding: 56px 24px 48px;
  }

  @media (min-width: 1024px) {
    padding-top: 32px;
    padding-bottom: 76px;
  }
`;

export const Desc = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 11px;
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Progress = styled.div`
  flex-shrink: 0;
  position: relative;
  width: 100%;
  max-width: 436px;
  height: 8px;
  background-color: #ededed;
  border-radius: 4px;
  overflow: hidden;
`;

export const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-primary);
`;

export const Item = styled.div`
  width: 100%;
  padding-top: 56px;
  padding-bottom: 56px;
  border-top: 1px solid #dcdfe9;

  @media (min-width: 1024px) {
    display: flex;
  }

  @media (min-width: 1024px) and (max-width: 1399px) {
    padding-right: 32px;
  }
`;

export const ItemTitle = styled.div`
  flex-shrink: 0;

  @media (max-width: 1023px) {
    margin-bottom: 24px;
  }

  @media (min-width: 1024px) {
    width: 328px;
  }
`;

export const ItemNumber = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  color: #fff;
  background-color: var(--color-primary);
  border-radius: 12px;
`;

export const ItemTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
`;

export const OptionLabel = styled.div`
  display: flex;
  align-items: center;
  border: solid 1px #dcdfe9;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:hover {
    border: solid 1px #8f95a5;
  }

  &.--brand {
    justify-content: center;
  }

  &.--default {
    height: 64px;
  }

  @media (max-width: 1023px) {
    &.--brand {
      width: 103px;
      height: 56px;
    }

    &.--default {
      width: 160px;
      padding: 16px 8px 16px 12px;
    }
  }

  @media (min-width: 1024px) {
    &.--brand {
      width: 88px;
      height: 50px;
    }

    &.--default {
      width: 184px;
      padding: 16px 8px 16px 16px;
    }
  }
`;

export const OptionInput = styled.input`
  display: none;

  &:checked + ${OptionLabel} {
    border: solid 1px #e1000f;
  }
`;

export const OptionIcon = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  background-color: #dbdfea;
  border-radius: 16px;
`;

export const SubmitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;

  @media (max-width: 1023px) {
    margin-top: 40px;
  }

  @media (min-width: 1024px) {
    margin-top: 80px;
  }
`;

export const CancelContainer = styled.div`
  @media (max-width: 1023px) {
    margin-top: 24px;
  }

  @media (min-width: 1024px) {
    margin-top: 32px;
  }
`;
