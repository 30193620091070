import React, { useRef, useState } from "react";
import { Link } from "../Link/Link";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import Cookies from "js-cookie";
import { motion, AnimatePresence } from "framer-motion";
import { useSearchParams, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { DateTime } from "luxon";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "lodash";
import { apiFetch } from "../../utils/api-fetch";
import {
  Root,
  Title,
  Subtitle,
  SubtitleDivider,
  BackButton,
  RedLink,
} from "./AuthRegister.style";

import { Button } from "../../ui/Button/Button";
import { Icon } from "../../ui/Icon/Icon";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "../../ui/Tooltip/Tooltip";

import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Step3 } from "./Step3";

import { apiSiteId, isMasterEnv, frontendDomain } from "../../config";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const websiteRegExp =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

const step1Schema = {
  firstName: yup.string().required().label("first name"),
  lastName: yup.string().required().label("last name"),
  email: yup.string().email().required(),
  password: yup.string().min(8).max(24).required(),
  mobile: yup
    .string()
    .matches(
      phoneRegExp,
      "Phone number is not valid.  Must have format 555-555-5555"
    )
    .required(),
  profession: yup.string().required(),
  distributor: yup
    .array()
    .min(1)
    .max(3)
    .of(
      yup.object({
        _id: yup.string().required("Distributor is a required field"),
        accountNumber: yup
          .string()
          .required("Distributor account number is a required field")
          .label("account number"),
      })
    ),
};

const step2Schema = {
  // accountNumber: "",
  salonName: yup.string().required().label("salon name"),
  salonBirthday: yup
    .string()
    .test(
      "date validation",
      "Invalid date or format. The value should in MM/DD/YYYY format.",
      (val) => {
        if (!val) {
          return true;
        }
        return DateTime.fromFormat(val, "M/d/yyyy").isValid;
      }
    )
    .required()
    .label("salon birthday"),
  salonAddress1: yup.string().required().label("salon address 1"),
  salonAddress2: yup.string().label("salon address 2"),
  province: yup.string().required(),
  city: yup.string().required(),
  postalCode: yup.string().required().label("postal code"),
  website: yup.string(),
  // .matches(
  //   /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
  //   "Invalid url.  Must have format https://example.com"
  // ),
  terms: yup.boolean().oneOf([true], "Field must be checked").required(),
  promotions: yup.boolean().required(),
};

const step3Schema = {
  avatar: yup
    .object({
      media: yup.string().required(),
      focalSelector: yup.string().required(),
    })
    .nullable(),
};

const schema = yup.object({
  ...step1Schema,
  ...step2Schema,
  ...step3Schema,
});

export function AuthRegister() {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const intl = useIntl();
  const divRef = useRef(null);
  const [statusMessage, setStatusMessage] = useState();
  const formRef = useRef(null);
  const [step, setStep] = useState(1);
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    watch,
    getFieldState,
    trigger,
    reset,
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
    defaultValues: {
      context: {
        id: "clubh",
        domain: frontendDomain,
      },
      firstName: "",
      lastName: "",
      email: searchParams.has("email") ? searchParams.get("email") : "",
      password: "",
      mobile: "",
      profession: "",
      distributor: [{ name: "", accountNumber: "" }],
      salonName: "",
      salonBirthday: "",
      salonAddress1: "",
      salonAddress2: "",
      province: "",
      city: "",
      postalCode: "",
      website: "",
      terms: false,
      promotions: false,
      avatar: null,
    },
  });

  const formValue = watch();
  // console.info({ intl, formValue, isSubmitting, isValid, errors });

  const step1IsValid = Object.keys(step1Schema).every((key) => {
    const fieldSchema = yup.object({ [key]: step1Schema[key] });
    let validateResult = false;
    try {
      validateResult = fieldSchema.validateSync({ [key]: formValue[key] });
    } catch (error) {}
    return validateResult === false ? false : true;
  });

  const step2IsValid = Object.keys(step2Schema).every((key) => {
    const fieldSchema = yup.object({ [key]: step2Schema[key] });
    let validateResult = false;
    try {
      validateResult = fieldSchema.validateSync({ [key]: formValue[key] });
    } catch (error) {}
    return validateResult === false ? false : true;
  });

  const scrollToTop = () => {
    divRef.current.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  };

  const handleChangeStep = (dir) => {
    if (dir < 0 && step > 1) {
      setStep(step - 1);
    } else {
      setStep(step + 1);
    }
    scrollToTop();
  };

  const onSubmit = (data) => {
    console.log(data);
    const referral = Cookies.get("referral");
    return apiFetch({
      path: `/${apiSiteId}/auth/register`,
      data: { ...data, referral, locale: intl.locale },
    })
      .then(({ result, response }) => {
        // console.info({ loginResult: result });
        if (result?.responseCode === 1) {
          throw new Error(
            result?.responseDesc ||
              "An unknown error occurred. Please try again."
          );
        }
        reset();
        setStep(1);
        scrollToTop();
        setStatusMessage({
          type: "success",
          message:
            intl.locale === "fr-CA"
              ? "Votre candidature a été soumise avec succès. Une fois approuvée, vous aurez accès à des récompenses exclusives et à une inspiration créative. Surveillez votre boîte de réception pour des mises."
              : "Your application has been successfully submitted. Once approved, you will have access to exclusive rewards and creative inspiration. Watch your inbox for updates!.",
          action: {
            label: "dismiss",
            onClick: () => navigate("/"),
          },
        });
        return result;
      })
      .catch((err) => {
        setStatusMessage({
          type: "error",
          message: err.message,
          // action: {
          //   label: "login here",
          //   onClick: () => navigate("/auth"),
          // },
        });
        //throw err;
      });
  };

  return (
    <Root ref={divRef}>
      <Title>
        <FormattedMessage defaultMessage="Register" />
      </Title>
      <Subtitle>
        {step > 1 && (
          <BackButton type="button" onClick={() => handleChangeStep(-1)}>
            <Icon icon="arrow-left-long" />
          </BackButton>
        )}
        <FormattedMessage defaultMessage="Step {step} of 3" values={{ step }} />
        <SubtitleDivider />
        {step === 1 && <FormattedMessage defaultMessage="User Profile" />}
        {step === 2 && <FormattedMessage defaultMessage="Salon Profile" />}
        {step === 3 && (
          <>
            <FormattedMessage defaultMessage="Your Avatar" />
            <Tooltip placement="top-start">
              <TooltipTrigger>
                <Icon
                  icon="info-circle"
                  style={{ marginTop: 1, marginLeft: 4, fontSize: 16 }}
                />
              </TooltipTrigger>
              <TooltipContent>
                <FormattedMessage defaultMessage="Optional (you can upload it later)" />
              </TooltipContent>
            </Tooltip>
          </>
        )}
      </Subtitle>

      {statusMessage != null ? (
        <p>
          {statusMessage.message}{" "}
          <button
            style={{ textDecoration: "underline" }}
            onClick={() => {
              setStatusMessage();
              if (statusMessage?.action?.onClick) {
                statusMessage.action.onClick();
              }
            }}
          >
            {statusMessage?.action?.label ?? (
              <FormattedMessage defaultMessage="dismiss" />
            )}
          </button>
        </p>
      ) : (
        <>
          <form ref={formRef}>
            <AnimatePresence mode="wait">
              <motion.div
                key={step}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                {step === 1 && (
                  <Step1 errors={errors} control={control} trigger={trigger} />
                )}
                {step === 2 && (
                  <Step2 errors={errors} control={control} trigger={trigger} />
                )}
                {step === 3 && (
                  <Step3 errors={errors} control={control} trigger={trigger} />
                )}
              </motion.div>
            </AnimatePresence>
          </form>

          {step === 1 && (
            <Button
              disabled={!step1IsValid}
              type="button"
              variant="auth"
              arrow={false}
              onClick={() => handleChangeStep(+1)}
            >
              <FormattedMessage defaultMessage="next step" tagName="span" />
              &nbsp;&nbsp;
              <Icon icon="arrow-right-long" />
            </Button>
          )}

          {step === 2 && (
            <Button
              disabled={!step1IsValid || !step2IsValid}
              type="button"
              variant="auth"
              arrow={false}
              onClick={() => handleChangeStep(+1)}
            >
              <FormattedMessage defaultMessage="next step" tagName="span" />
              &nbsp;&nbsp;
              <Icon icon="arrow-right-long" />
            </Button>
          )}

          {step === 3 && (
            <Button
              type="button"
              variant="auth"
              arrow={false}
              disabled={!isEmpty(errors) || isSubmitting || !isValid}
              onClick={handleSubmit((d) => onSubmit(d))}
            >
              <FormattedMessage defaultMessage="join now" tagName="span" />
            </Button>
          )}

          {step === 3 ? (
            <RedLink
              as="button"
              disabled={isSubmitting || !isValid}
              onClick={handleSubmit((d) => onSubmit(d))}
            >
              <FormattedMessage defaultMessage="Skip & Join Now" />
            </RedLink>
          ) : (
            !isMasterEnv && (
              <RedLink as={Link} to="/auth">
                <FormattedMessage defaultMessage="Login" />
              </RedLink>
            )
          )}
        </>
      )}
    </Root>
  );
}
