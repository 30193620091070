import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useOutletContext, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { set } from "lodash";

import { EarnBonusPointsContainer, Table, TableLink } from "./MyAccount.style";

import { Typography } from "../../ui/Typography/Typography";
import { Link } from "../Link/Link";
import { ButtonLink } from "../../ui/ButtonLink/ButtonLink";
import { ModalRoot } from "../Modals/ModalRoot/ModalRoot";
import { ModalSurvey } from "../Modals/ModalSurvey/ModalSurvey";
import { ModalBonusPointsEarned } from "../Modals/ModalBonusPointsEarned/ModalBonusPointsEarned";
import { ModalReferFriend } from "../Modals/ModalReferFriend/ModalReferFriend";

import { GET_POINTS_HISTORY } from "../Dashboard/config";
import { on } from "superagent";

export const EarnBonusPoints = ({ data, onSetExpanded }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const outletContext = useOutletContext();
  const userData = outletContext?.me?.user || data?.me?.user;
  const [modalSurveyVisible, setModalSurveyVisible] = useState(false);
  const [modalBonusPointsEarned, setModalBonusPointsEarned] = useState(null);
  const [modalReferFriend, setModalReferFriend] = useState(false);

  const {
    loading: pointsLoading,
    error: pointsError,
    data: pointsData,
  } = useQuery(GET_POINTS_HISTORY, {
    variables: {
      page: 1,
      sort: [],
      filters: [
        {
          key: "jobKey",
          operator: "idIn",
          value: "bonus-salon-birthday,bonus-sms-opt-in,bonus-preferences",
        },
      ],
    },
  });

  return (
    <EarnBonusPointsContainer>
      <Typography type="h2">
        <FormattedMessage defaultMessage="Bonus Points" />
      </Typography>

      <Table style={{ marginTop: 32, marginBottom: 80 }}>
        <thead>
          <tr>
            <th>
              <Typography type="text-small">
                <FormattedMessage defaultMessage="Your Actions" />
              </Typography>
            </th>
            <th>
              <Typography type="text-small">
                <FormattedMessage defaultMessage="Points You Earn" />
              </Typography>
            </th>
            <th>
              <Typography type="text-small">
                <FormattedMessage defaultMessage="Completed" />
              </Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <TableLink
                disabled={pointsData?.loyaltyresult?.find?.docs.some(
                  (itm) => itm.jobKey === "bonus-preferences"
                )}
                onClick={() => setModalSurveyVisible(true)}
              >
                <Typography type="text-secondary">
                  <FormattedMessage defaultMessage="Complete your Preferences" />
                </Typography>
              </TableLink>
            </td>
            <td>
              <Typography type="points-primary">
                <FormattedMessage defaultMessage="8000 PTS" />
              </Typography>
            </td>
            <td>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                width={20}
                height={20}
                style={{ display: "inline-block" }}
              >
                <path
                  fill={
                    pointsData?.loyaltyresult?.find?.docs.some(
                      (itm) => itm.jobKey === "bonus-preferences"
                    )
                      ? "#A2ECBA"
                      : "#dbdfea"
                  }
                  d="M20.007 10c0 1.756-.462 3.48-1.34 5-3.849 6.667-13.471 6.667-17.32 0-3.849-6.666.962-15 8.66-15 5.52.006 9.994 4.48 10 10Zm-5.361-3.842-5.959 5.96h-.418L5.362 9.212l-1.01 1.01 3.62 3.62H8.98l6.676-6.677Z"
                />
              </svg>
            </td>
          </tr>
          <tr>
            <td>
              <TableLink
                disabled={pointsData?.loyaltyresult?.find?.docs.some(
                  (itm) => itm.jobKey === "bonus-sms-opt-in"
                )}
                onClick={() => {
                  onSetExpanded && onSetExpanded(4);
                  navigate(`/${intl.locale}/dashboard/my-account#sms`, {
                    preventScrollReset: true,
                  });
                }}
              >
                <Typography type="text-secondary">
                  <FormattedMessage defaultMessage="Subscribe to SMS updates" />
                </Typography>
              </TableLink>
            </td>
            <td>
              <Typography type="points-primary">
                <FormattedMessage defaultMessage="2000 PTS" />
              </Typography>
            </td>
            <td>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                width={20}
                height={20}
                style={{ display: "inline-block" }}
              >
                <path
                  fill={
                    pointsData?.loyaltyresult?.find?.docs.some(
                      (itm) => itm.jobKey === "bonus-sms-opt-in"
                    )
                      ? "#A2ECBA"
                      : "#dbdfea"
                  }
                  d="M20.007 10c0 1.756-.462 3.48-1.34 5-3.849 6.667-13.471 6.667-17.32 0-3.849-6.666.962-15 8.66-15 5.52.006 9.994 4.48 10 10Zm-5.361-3.842-5.959 5.96h-.418L5.362 9.212l-1.01 1.01 3.62 3.62H8.98l6.676-6.677Z"
                />
              </svg>
            </td>
          </tr>
          <tr>
            <td>
              <TableLink
                disabled={pointsData?.loyaltyresult?.find?.docs.some(
                  (itm) => itm.jobKey === "bonus-salon-birthday"
                )}
                onClick={() => {
                  onSetExpanded && onSetExpanded(3);
                  navigate(`/${intl.locale}/dashboard/salon-profile#birthday`, {
                    preventScrollReset: true,
                  });
                }}
              >
                <Typography type="text-secondary">
                  <FormattedMessage defaultMessage="Enter your Salon’s Birthday" />
                </Typography>
              </TableLink>
            </td>
            <td>
              <Typography type="points-primary">
                <FormattedMessage defaultMessage="2000 PTS" />
              </Typography>
            </td>
            <td>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                width={20}
                height={20}
                style={{ display: "inline-block" }}
              >
                <path
                  fill={
                    pointsData?.loyaltyresult?.find?.docs.some(
                      (itm) => itm.jobKey === "bonus-salon-birthday"
                    )
                      ? "#A2ECBA"
                      : "#dbdfea"
                  }
                  d="M20.007 10c0 1.756-.462 3.48-1.34 5-3.849 6.667-13.471 6.667-17.32 0-3.849-6.666.962-15 8.66-15 5.52.006 9.994 4.48 10 10Zm-5.361-3.842-5.959 5.96h-.418L5.362 9.212l-1.01 1.01 3.62 3.62H8.98l6.676-6.677Z"
                />
              </svg>
            </td>
          </tr>
          <tr>
            <td>
              <TableLink onClick={() => setModalReferFriend(true)}>
                <Typography type="text-secondary">
                  <FormattedMessage defaultMessage="Refer a Friend" />
                </Typography>
              </TableLink>
            </td>
            <td>
              <Typography type="points-primary">
                <FormattedMessage defaultMessage="5000 PTS" />
              </Typography>
            </td>
            <td>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                width={20}
                height={20}
                style={{ display: "inline-block" }}
              >
                <path
                  fill="#dbdfea"
                  d="M20.007 10c0 1.756-.462 3.48-1.34 5-3.849 6.667-13.471 6.667-17.32 0-3.849-6.666.962-15 8.66-15 5.52.006 9.994 4.48 10 10Zm-5.361-3.842-5.959 5.96h-.418L5.362 9.212l-1.01 1.01 3.62 3.62H8.98l6.676-6.677Z"
                />
              </svg>
            </td>
          </tr>
        </tbody>
      </Table>

      {/* <Typography type="h2">
        <FormattedMessage defaultMessage="Monthly Promotions" />
      </Typography>

      <Table style={{ marginTop: 32 }}>
        <tbody>
          <tr>
            <td>
              <Typography type="text-secondary">
                <FormattedMessage defaultMessage="2023 Winter Promotion" />
              </Typography>
              <Typography type="text-small" color="#8f95a5">
                <FormattedMessage defaultMessage="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse ut posuere nibh, sed commodo ligula. Etiam vulputate eget elit at malesuada. Aliquam erat volutpat." />
              </Typography>
            </td>
            <td>
              <ButtonLink as={Link} to="/">
                <Typography type="text-secondary">
                  <FormattedMessage defaultMessage="View Details" />
                </Typography>
              </ButtonLink>
            </td>
          </tr>
          <tr>
            <td>
              <Typography type="text-secondary">
                <FormattedMessage defaultMessage="2023 Winter Promotion" />
              </Typography>
              <Typography type="text-small" color="#8f95a5">
                <FormattedMessage defaultMessage="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse ut posuere nibh, sed commodo ligula. Etiam vulputate eget elit at malesuada. Aliquam erat volutpat." />
              </Typography>
            </td>
            <td>
              <ButtonLink as={Link} to="/">
                <Typography type="text-secondary">
                  <FormattedMessage defaultMessage="View Details" />
                </Typography>
              </ButtonLink>
            </td>
          </tr>
          <tr>
            <td>
              <Typography type="text-secondary">
                <FormattedMessage defaultMessage="2023 Winter Promotion" />
              </Typography>
              <Typography type="text-small" color="#8f95a5">
                <FormattedMessage defaultMessage="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse ut posuere nibh, sed commodo ligula. Etiam vulputate eget elit at malesuada. Aliquam erat volutpat." />
              </Typography>
            </td>
            <td>
              <ButtonLink as={Link} to="/">
                <Typography type="text-secondary">
                  <FormattedMessage defaultMessage="View Details" />
                </Typography>
              </ButtonLink>
            </td>
          </tr>
        </tbody>
      </Table> */}

      <ModalRoot
        isVisible={modalSurveyVisible}
        onClose={() => setModalSurveyVisible(false)}
        fullScreen
      >
        <ModalSurvey
          userData={userData}
          onClose={() => setModalSurveyVisible(false)}
          cb={(data) => {
            setModalSurveyVisible(false);
            setTimeout(() => {
              if (data) {
                setModalBonusPointsEarned(data);
              }
            }, 550);
          }}
        />
      </ModalRoot>

      <ModalRoot
        isVisible={modalBonusPointsEarned != null}
        onClose={() => setModalBonusPointsEarned(null)}
      >
        <ModalBonusPointsEarned
          {...modalBonusPointsEarned}
          onClose={() => setModalBonusPointsEarned(null)}
        />
      </ModalRoot>

      <ModalRoot
        isVisible={modalReferFriend}
        onClose={() => setModalReferFriend(false)}
      >
        <ModalReferFriend
          onClose={() => setModalReferFriend(false)}
          userId={userData._id}
        />
      </ModalRoot>
    </EarnBonusPointsContainer>
  );
};
