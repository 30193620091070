import { gql } from "@apollo/client";

export const contentTypes = ["news", "page"];

export function getTitle({ intl, type }) {
  return type === "news"
    ? intl.formatMessage({
        defaultMessage: "Experiences",
      })
    : intl.formatMessage({
        defaultMessage: "Education",
      });
}

export function categoriesConfig({ intl }) {
  return [
    // {
    //   value: "promotions",
    //   label: intl.formatMessage({
    //     defaultMessage: "Promotions & Deals",
    //   }),
    // },
    {
      value: "product-launches",
      label: intl.formatMessage({
        defaultMessage: "New Launches",
      }),
    },
    {
      value: "events",
      label: intl.formatMessage({
        defaultMessage: "Events",
      }),
    },
    {
      value: "education",
      label: intl.formatMessage({
        defaultMessage: "Education",
      }),
    },
    {
      value: "new",
      label: intl.formatMessage({
        defaultMessage: "Stories",
      }),
    },
  ];
}

export const sortConfig = [
  { label: "Sort Name Asc", value: "name-asc", key: "n", dir: "1" },
  {
    label: "Sort Latest Items",
    value: "lastest-desc",
    key: "dc",
    dir: "-1",
  },
];

export const GET_CONTENT_ITEM = gql`
  query GetContentItem($slug: String!, $locale: String) {
    content {
      findBySlug(slug: $slug, locale: $locale) {
        ... on News {
          _id
          n
          desc
          sl
          dc
          dm
          brand {
            _id
            n
          }
          gallery {
            media {
              _id
            }
          }
          galleryMobile {
            media {
              _id
            }
          }
          data {
            youtubeUrl
            isFeatured
          }
        }
        ... on Page {
          _id
          n
          desc
          sl
          dc
          dm
          gallery {
            media {
              _id
            }
          }
          galleryMobile {
            media {
              _id
            }
          }
        }
      }
    }
  }
`;

export const GET_CONTENT = gql`
  query GetNewsContent(
    $sort: [SortInput]
    $page: Int
    $filters: String
    $limit: Int
    $locale: String
  ) {
    content {
      findClubH(
        sort: $sort
        page: $page
        filters: $filters
        limit: $limit
        locale: $locale
      ) {
        docs {
          ... on News {
            _id
            n
            desc
            sl
            dc
            dm
            type
            brand {
              _id
              n
            }
            gallery {
              media {
                _id
              }
            }
            data {
              youtubeUrl
              isFeatured
            }
          }
        }
        page
        pages
        limit
        total
      }
    }
  }
`;

export const contentTagsPath = "data.content.findRewardTags";

export const contentDataPath = "content.findClubH";

export const GET_CONTENT_TAGS = gql`
  query GetContentTags($startsWith: String) {
    content {
      findRewardTags(startsWith: $startsWith)
    }
  }
`;
