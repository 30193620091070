export default {
  AUTH: 'auth',
};

export const AuthActions = {
  SIGNUP: 'signUp',
  SIGNIN: 'signIn',
  SIGNOUT: 'signOut',
  CURRENT_AUTH_USER: 'currentAuthenticatedUser',
  SESSION_EXPIRING: 'sessionExpiring',
  VERIFY: 'verify',
  RESET: 'reset',
  FORGOT: 'forgot',
};
