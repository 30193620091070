import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { ComingSoonView } from "../views/ComingSoonView/ComingSoonView";
import { SweepstakesView } from "../views/SweepstakesView/SweepstakesView";

import { HomeView } from "../views/HomeView/HomeView";
import { ErrorView } from "../views/ErrorView/ErrorView";
import { NotFoundView } from "../views/NotFoundView/NotFoundView";
import { AuthView } from "../views/AuthView/AuthView";
import { TermsView } from "../views/TermsView/TermsView";
import { AuthLogin } from "../components/AuthLogin/AuthLogin";
import { AuthForgot } from "../components/AuthForgot/AuthForgot";
import { AuthRegister } from "../components/AuthRegister/AuthRegister";
import { AuthReset } from "../components/AuthReset/AuthReset";
import { RewardsView } from "../views/RewardsView/RewardsView";
import { RewardsDetailView } from "../views/RewardsDetailView/RewardsDetailView";
import { ContentView } from "../views/ContentView/ContentView";
import { ContentDetailView } from "../views/ContentDetailView/ContentDetailView";
import { OffersView } from "../views/OffersView/OffersView";
import { OffersDetailView } from "../views/OffersDetailView/OffersDetailView";
// import { EducationView } from "../views/EducationView/EducationView";
// import { EducationDetailView } from "../views/EducationDetailView/EducationDetailView";
import { CommunityView } from "../views/CommunityView/CommunityView";
import { FavouritesView } from "../views/FavouritesView/FavouritesView";
import { CartView } from "../views/CartView/CartView";
import { CheckoutView } from "../views/CheckoutView/CheckoutView";
import { OrderConfirmationView } from "../views/OrderConfirmationView/OrderConfirmationView";
import { HowItWorksView } from "../views/HowItWorksView/HowItWorksView";
// import { AccountView } from "../views/AccountView/AccountView";
import { DashboardView } from "../views/DashboardView/DashboardView";
import { DashboardOrderView } from "../views/DashboardOrderView/DashboardOrderView";
import { BrandView } from "../views/BrandView/BrandView";
import { Intl } from "../components/Intl/Intl";
import { AccessControl } from "../components/AccessControl/AccessControl";
import { PointsRedeemed } from "../components/Dashboard/PointsRedeemed";
import { PointsEarned } from "../components/Dashboard/PointsEarned";
import { MyProfile } from "../components/MyAccount/MyProfile";
import { MySalon } from "../components/MyAccount/MySalon";
import { EarnBonusPoints } from "../components/MyAccount/EarnBonusPoints";

import { isMasterEnv } from "../config";
import { SweepsTermsView } from "../views/SweepsTermsView/SweepsTermsView";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    path: "/:locale?",
    caseSensitive: true,
    element: <Intl />,
    children: [
      {
        index: true,
        element: <HomeView />,
        errorElement: <ErrorView />,
      },
      // {
      //   path: "comingsoon",
      //   element: <ComingSoonView />,
      //   errorElement: <ErrorView />,
      // },
      // {
      //   path: "sweepintostyle",
      //   element: <SweepstakesView />,
      //   errorElement: <ErrorView />,
      // },
      {
        path: "terms",
        element: <TermsView />,
        errorElement: <ErrorView />,
      },
      {
        path: "sweeps-terms",
        element: <SweepsTermsView />,
        errorElement: <ErrorView />,
      },
      {
        path: "rewards",
        element: <RewardsView />,
        errorElement: <ErrorView />,
      },
      {
        path: "rewards/:slug",
        element: <RewardsDetailView />,
        errorElement: <ErrorView />,
      },
      {
        path: "offers",
        element: <OffersView type="news" />,
        errorElement: <ErrorView />,
      },
      {
        path: "offers/:slug",
        element: <OffersDetailView type="news" />,
        errorElement: <ErrorView />,
      },
      {
        path: "experience",
        element: <ContentView type="news" />,
        errorElement: <ErrorView />,
      },
      {
        path: "news/:slug",
        element: <ContentDetailView type="news" />,
        errorElement: <ErrorView />,
      },
      {
        path: "page/:slug",
        element: <ContentDetailView type="page" />,
        errorElement: <ErrorView />,
      },
      // {
      //   path: "education",
      //   element: <ContentView type="education" />,
      //   errorElement: <ErrorView />,
      // },
      // {
      //   path: "education/:slug",
      //   element: <ContentDetailView type="education" />,
      //   errorElement: <ErrorView />,
      // },
      {
        path: "community",
        element: <CommunityView />,
        errorElement: <ErrorView />,
      },
      {
        path: "favourites",
        element: <FavouritesView />,
        errorElement: <ErrorView />,
      },
      {
        path: "cart",
        element: <CartView />,
        errorElement: <ErrorView />,
      },
      {
        path: "order-confirmation",
        element: (
          <AccessControl
            unAuthorizedUrlRedirect="/"
            addRedirectQueryParam={false}
            hasAccess={(user) => user != null}
          >
            <OrderConfirmationView />,
          </AccessControl>
        ),
        errorElement: <ErrorView />,
      },
      {
        path: "membership-benefits",
        element: <HowItWorksView />,
      },
      {
        path: "brands/:brandSlug",
        element: <BrandView />,
      },
      {
        path: "checkout",
        element: (
          <AccessControl
            unAuthorizedUrlRedirect="/"
            addRedirectQueryParam={false}
            hasAccess={(user) => user != null}
          >
            <CheckoutView />
          </AccessControl>
        ),
        errorElement: <ErrorView />,
      },
      // {
      //   path: "account",
      //   element: (
      //     <AccessControl
      //       unAuthorizedUrlRedirect="/auth"
      //       addRedirectQueryParam={true}
      //       hasAccess={(user) => user != null}
      //     >
      //       <AccountView />
      //     </AccessControl>
      //   ),
      // },
      {
        path: "dashboard/order/:orderId",
        element: (
          <AccessControl
            unAuthorizedUrlRedirect="/auth"
            addRedirectQueryParam={true}
            hasAccess={(user) => user != null}
          >
            <DashboardOrderView />
          </AccessControl>
        ),
      },
      {
        path: "dashboard",
        element: (
          <AccessControl
            unAuthorizedUrlRedirect="/auth"
            addRedirectQueryParam={true}
            hasAccess={(user) => user != null}
          >
            <DashboardView />
          </AccessControl>
        ),
        children: [
          {
            index: true,
            element: <PointsEarned />,
            errorElement: <ErrorView />,
          },
          {
            path: "orders",
            element: <PointsRedeemed />,
            errorElement: <ErrorView />,
          },
          {
            path: "my-account",
            element: <MyProfile />,
            errorElement: <ErrorView />,
          },
          {
            path: "salon-profile",
            element: <MySalon />,
            errorElement: <ErrorView />,
          },
          {
            path: "bonus-points",
            element: <EarnBonusPoints />,
            errorElement: <ErrorView />,
          },
        ],
      },
      /*
          {
            path: "dashboard",
            element: (
              <AccessControl
                unAuthorizedUrlRedirect="/auth"
                addRedirectQueryParam={true}
                hasAccess={(user) => user != null}
              >
                  <DashboardView />
              </AccessControl>
            ),
          },
          */
      {
        path: "auth",
        element: (
          <AccessControl
            unAuthorizedUrlRedirect="/"
            addRedirectQueryParam={false}
            hasAccess={(user) => user == null}
          >
            <AuthView />
          </AccessControl>
        ),
        children: [
          {
            index: true,
            element: <AuthLogin />,
            errorElement: <ErrorView />,
          },
          {
            path: "register",
            element: <AuthRegister />,
            errorElement: <ErrorView />,
          },
          {
            path: "forgot",
            element: <AuthForgot />,
            errorElement: <ErrorView />,
          },
          {
            path: "reset/:token",
            element: <AuthReset />,
            errorElement: <ErrorView />,
          },
        ],
      },
      {
        path: "*",
        element: <NotFoundView />,
        errorElement: <ErrorView />,
      },
    ],
  },
]);

/* 

[
          {
            index: true,
            element: <ComingSoonView />,
            errorElement: <ErrorView />,
          },
          {
            path: "terms",
            element: <TermsView />,
            errorElement: <ErrorView />,
          },
          {
            path: "auth",
            element: (
              <AccessControl
                unAuthorizedUrlRedirect="/"
                addRedirectQueryParam={false}
                hasAccess={(user) => user == null}
              >
                <AuthView />
              </AccessControl>
            ),
            children: [
              // {
              //   index: true,
              //   element: <AuthLogin />,
              //   errorElement: <ErrorView />,
              // },
              {
                path: "register",
                element: <AuthRegister />,
                errorElement: <ErrorView />,
              },
              // {
              //   path: "forgot",
              //   element: <AuthForgot />,
              //   errorElement: <ErrorView />,
              // },
              // {
              //   path: "reset/:token",
              //   element: <AuthReset />,
              //   errorElement: <ErrorView />,
              // },
            ],
          },
          {
            path: "*",
            element: <NotFoundView />,
            errorElement: <ErrorView />,
          },
        ]

        */
