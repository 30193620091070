import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { DateTime } from "luxon";
import { Helmet } from "react-helmet-async";

import {
  Root,
  Title,
  DetailsList,
  DetailsItem,
  DropdownButton,
  BannerContainer,
  ContentContainer,
  LoadingContainer,
  PlayButton,
} from "./ContentDetail.style";

import { Link } from "../Link/Link";
import { Icon } from "../../ui/Icon/Icon";
import { Typography } from "../../ui/Typography/Typography";
import { Loading } from "../Loading/Loading";
import { List } from "../../ui/List/List";
import { Citation } from "../../ui/Citation/Citation";
import { ContentDetailTags } from "../ContentDetailTags/ContentDetailTags";
import { MenuDropdown } from "../../ui/MenuDropdown/MenuDropdown";

const readingTime = (text) => {
  const averageWPM = 250;
  const words = text.trim().split(/\s+/);
  const adjustedText = text.replace(/(.)\1+/g, "$1");
  const adjustedSentences = adjustedText.replace(/([.!?])\s*\1+/g, "$1");
  const adjustedCharCount = adjustedSentences.length;
  const adjustedWords = adjustedSentences.trim().split(/\s+/);
  const adjustedWordCount = adjustedWords.length;
  const averageWordLength = adjustedCharCount / adjustedWordCount;
  const adjustedTime =
    (adjustedCharCount / averageWPM) * (averageWordLength / 5);
  const formattedAdjustedTime =
    adjustedTime > 1 ? (
      <FormattedMessage
        defaultMessage="{time} min read"
        values={{
          time: Math.round(adjustedTime),
        }}
        tagName="span"
      />
    ) : (
      <FormattedMessage defaultMessage="Less than 1min read" tagName="span" />
    );

  return formattedAdjustedTime;
};

export const ContentDetail = ({
  brand = "",
  brandUrl = "",
  title = "",
  desc = "",
  publishedAt,
  bannerSrc = "",
  bannerMobileSrc,
  videoSrc,
  loading = false,
}) => {
  const shareMenuRef = useRef();
  const [shareMenuIsOpen, setShareMenuIsOpen] = useState(false);
  const encodedFullPath = encodeURIComponent(window.location.href);
  const encodedImageSrc = encodeURIComponent(bannerSrc);
  const encodedTitle = encodeURIComponent(title.substring(0, 280).trim());
  const [showVideo, setShowVideo] = useState(false);

  const youtubeIdRegex =
    /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
  const videoId = videoSrc != null ? youtubeIdRegex.exec(videoSrc)?.[3] : null;

  return loading ? (
    <LoadingContainer>
      <Loading global={false} />
    </LoadingContainer>
  ) : (
    <Root>
      {title && brand && (
        <Helmet>
          <title>
            {title} - {brand}
          </title>
        </Helmet>
      )}

      <Typography as={Link} to={brandUrl} type="brand-primary">
        {brand}
      </Typography>

      <Title>
        <Typography as="h1" type="h1">
          {title}
        </Typography>
      </Title>

      <DetailsList>
        <DetailsItem>
          <Typography type="text-small">
            {DateTime.fromMillis(publishedAt).toLocaleString(
              DateTime.DATE_FULL
            )}
          </Typography>
        </DetailsItem>
        {/* <DetailsItem>
          <Typography type="text-small">{readingTime(desc)}</Typography>
        </DetailsItem> */}
        <DetailsItem>
          <DropdownButton
            className={`shareDropdownAction ${shareMenuIsOpen && "--isActive"}`}
            ref={shareMenuRef}
            type="button"
            onClick={() => setShareMenuIsOpen(!shareMenuIsOpen)}
          >
            <Icon icon="share" color="#8f95a5" />

            <MenuDropdown
              isOpen={shareMenuIsOpen}
              onClose={() => setShareMenuIsOpen(false)}
              ignoreClassName="shareDropdownAction"
            >
              <MenuDropdown.Item
                as={Link}
                to={`mailto:?subject=${encodedTitle}&body=${encodedFullPath}`}
                target="_blank"
              >
                <Icon icon="mail" />
                <Typography type="h4">
                  <FormattedMessage defaultMessage="Email" />
                </Typography>
              </MenuDropdown.Item>
              <MenuDropdown.Item
                as={Link}
                to={`https://pinterest.com/pin/create/button/?url=${encodedFullPath}&media=${encodedImageSrc}`}
                target="_blank"
              >
                <Icon icon="pinterest" />
                <Typography type="h4">
                  <FormattedMessage defaultMessage="Pinterest" />
                </Typography>
              </MenuDropdown.Item>
              <MenuDropdown.Item
                as={Link}
                to={`https://www.facebook.com/sharer/sharer.php?u=${encodedFullPath}`}
                target="_blank"
              >
                <Icon icon="facebook" style={{ fontSize: 17 }} />
                <Typography type="h4">
                  <FormattedMessage defaultMessage="Facebook" />
                </Typography>
              </MenuDropdown.Item>
              <MenuDropdown.Item
                as={Link}
                to={`https://twitter.com/intent/tweet?text=${encodedTitle}&url=${encodedFullPath}`}
                target="_blank"
              >
                <Icon icon="x" />
                <Typography type="h4">
                  <FormattedMessage defaultMessage="X" />
                </Typography>
              </MenuDropdown.Item>
              <MenuDropdown.Item
                role="button"
                tabIndex={0}
                onClick={() =>
                  navigator.clipboard.writeText(window.location.href)
                }
              >
                <Icon icon="link" />
                <Typography type="h4">
                  <FormattedMessage defaultMessage="Copy Link" />
                </Typography>
              </MenuDropdown.Item>
            </MenuDropdown>
          </DropdownButton>
        </DetailsItem>
      </DetailsList>

      <BannerContainer>
        {!showVideo ? (
          <>
            <picture>
              {bannerMobileSrc && (
                <source media="(max-width: 1023px)" srcSet={bannerMobileSrc} />
              )}
              <img src={bannerSrc} alt={title} />
            </picture>
            {videoId && (
              <PlayButton onClick={() => setShowVideo(true)}>
                <Icon icon="youtube" />
              </PlayButton>
            )}
          </>
        ) : (
          <iframe
            src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </BannerContainer>

      <ContentContainer>
        <Typography
          as="p"
          type="text-primary"
          dangerouslySetInnerHTML={{ __html: desc }}
        />
        {/* <Typography as="p" type="text-primary">
          Since its inception in 1997, there have been over{" "}
          <a href="/">40 ESSENTIAL LOOKS Collections</a>, translating
          directional runway trends into wearable salon looks for hairdressers
          worldwide. Many of the world’s top models have appeared in ESSENTIAL
          LOOKS, including Claudia Schiffer, Eva Padberg, Charlotte Cordes and
          Luping Wang, Katharina Damm and ex-supermodels Daniella Pestova and
          Ingrid Parewijck. ESSENTIAL LOOKS is an integral part of the
          Schwarzkopf Professional® service offer; providing hairdressers with
          everything they need to enhance their creativity, maximize their
          skills and achieve salon success.
        </Typography>
        <Typography as="p" type="text-primary">
          It’s a fast, furious, brand new world, and we’re right there pinning
          down the trends that really matter. The REinventing Hair Collection
          for Spring/Summer 2018 includes 4 trends: Random Rebel, Real Me,
          Beyond Borders, and #RoyalTakeOver. In 2018, club culture is on the
          rise and <a href="/">RANDOM REBEL</a> celebrates the cultural outsider
          and the cult of the maverick. BEYOND BORDERS embraces our global
          blended status – a new generation of multiculture where attitude is
          everything and no rules rule. In REAL ME, we’re embracing authentic,
          real life, just dialed up – in every shade of natural.
        </Typography>

        <Typography as="h2" type="h2">
          Placeholder for primary title
        </Typography>

        <Typography as="p" type="text-primary">
          Along with our three trends, we’re launching our brand- new color
          story, IGORA #RoyalTakeOver. We’ve handed over IGORA ROYAL to the
          world’s top digital influencers and teamed them up with global leading
          colorists – to play, experiment, dream, and REinspire. In our
          industry, we’re all continuously reinventing hair in every dimension.
          That’s the only way to thrive and grow. At Schwarzkopf Professional,
          it’s our mission, today and every day. We’re always inspiring,
          creating and always dreaming big.
        </Typography>

        <img src="https://picsum.photos/id/58/2800/1100" alt="" />

        <Typography as="h2" type="h2">
          Placeholder for primary title
        </Typography>

        <Typography as="p" type="text-primary">
          Along with our three trends, we’re launching our brand- new color
          story, IGORA #RoyalTakeOver. We’ve handed over IGORA ROYAL to the
          world’s top digital influencers and teamed them up with global leading
          colorists – to play, experiment, dream, and REinspire. In our
          industry, we’re all continuously reinventing hair in every dimension.
          That’s the only way to thrive and grow. At Schwarzkopf Professional,
          it’s our mission, today and every day. We’re always inspiring,
          creating and always dreaming big.
        </Typography>

        <Citation
          quote="Here goes my quote consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh viverra non semper suscipit posuere a pede."
          caption="Author Name"
        />

        <Typography as="p" type="text-primary">
          Along with our three trends, we’re launching our brand- new color
          story, IGORA #RoyalTakeOver. We’ve handed over IGORA ROYAL to the
          world’s top digital influencers and teamed them up with global leading
          colorists – to play, experiment, dream, and REinspire. In our
          industry, we’re all continuously reinventing hair in every dimension.
          That’s the only way to thrive and grow. At Schwarzkopf Professional,
          it’s our mission, today and every day. We’re always inspiring,
          creating and always dreaming big.
        </Typography>

        <Typography as="h3" type="h3">
          Secondary title with a bullet list
        </Typography>

        <Typography as="p" type="text-primary">
          Along with our three trends, we’re launching our brand- new color
          story, IGORA #RoyalTakeOver. We’ve handed over IGORA ROYAL to the
          world’s top digital influencers and teamed them up with global leading
          colorists – to play, experiment, dream, and REinspire. In our
          industry, we’re all continuously reinventing hair in every dimension.
        </Typography>

        <br />

        <List>
          <li>
            <Typography type="text-primary" block={false}>
              Vivamus vestibulum ntulla nec ante.
            </Typography>
          </li>
          <li>
            <Typography type="text-primary" block={false}>
              Praesent placerat risus quis eros.
            </Typography>
          </li>
          <li>
            <Typography type="text-primary" block={false}>
              Fusce pellentesque suscipit nibh.
            </Typography>
          </li>
          <li>
            <Typography type="text-primary" block={false}>
              Integer vitae libero ac risus egestas placerat.
            </Typography>
          </li>
          <li>
            <Typography type="text-primary" block={false}>
              Vestibulum commodo felis quis tortor.
            </Typography>
          </li>
          <li>
            <Typography type="text-primary" block={false}>
              Ut aliquam sollicitudin leo.
            </Typography>
          </li>
          <li>
            <Typography type="text-primary" block={false}>
              Cras iaculis ultricies nulla.
            </Typography>
          </li>
          <li>
            <Typography type="text-primary" block={false}>
              Donec quis dui at dolor tempor interdum.
            </Typography>
          </li>
        </List>

        <Typography as="h3" type="h3">
          Secondary title with a bullet list
        </Typography>

        <Typography as="p" type="text-primary">
          Along with our three trends, we’re launching our brand- new color
          story, IGORA #RoyalTakeOver. We’ve handed over IGORA ROYAL to the
          world’s top digital influencers and teamed them up with global leading
          colorists – to play, experiment, dream, and REinspire. In our
          industry, we’re all continuously reinventing hair in every dimension.
        </Typography>

        <br />

        <List as="ol">
          <li>
            <Typography type="text-primary" block={false}>
              Lorem ipsum dolor sit amet, consectetuer.
            </Typography>
          </li>
          <li>
            <Typography type="text-primary" block={false}>
              Aliquam tincidunt mauris eu risus.
            </Typography>
          </li>
          <li>
            <Typography type="text-primary" block={false}>
              Vestibulum auctor dapibus neque.
            </Typography>
          </li>
          <li>
            <Typography type="text-primary" block={false}>
              Nunc dignissim risus id metus.
            </Typography>
          </li>
        </List>

        <ContentDetailTags
          label="Category"
          tags={[{ label: "New Launches", url: "/" }]}
          style={{ marginTop: 54 }}
        />

        <ContentDetailTags
          tags={[
            { label: "Care", url: "/" },
            { label: "Hair", url: "/" },
            { label: "Curls", url: "/" },
            { label: "Heat", url: "/" },
          ]}
        /> */}
      </ContentContainer>
    </Root>
  );
};
